/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Spinner,
  useToast,
  VStack
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { format, parseISO } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { Input } from '../../Components/Form/Input'
import { InputMaskCustom } from '../../Components/Form/InputMask'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TextArea } from '../../Components/Form/TextArea'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { AiOutlineUser } from 'react-icons/ai'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { MdPassword } from 'react-icons/md'
import { BsLock } from 'react-icons/bs'
import { removeNonNumeric } from '../../utils/removeNonNumeric'
import { CepProps } from '../Providers/create'
import { Loader } from '../../Components/Loader'
import { FilesProps } from '../Process/Interfaces/ProcessIntefaces'
import { Can } from '../../Components/Can'
import { FaUmbrellaBeach } from 'react-icons/fa'

interface CollaboratorProps {
  department: string
  date_init: string
  date_end: string
  office: string
  officeLabel: string
  name: string
  gender: string
  born: string
  rg: string
  date_document: string
  organ: string
  cpf: string
  reserve_doc: string
  voter_doc: string
  pis: string
  work_doc: string
  email: string
  tel: string
  phone: string
  obs: string
  address: {
    cep: string
    street: string
    number: string
    complement: string
    neighborhood: string
    city: string
    state: string
  }
  bank: {
    bank: string
    account: string
    agency: string
    pix: string
  }
  files: FilesProps[]
  team: string
  boss: string
  access: {
    nets: number
    units: number
    providers: number
    collaborators: number
    proccess: number
    bills_to_pay: number
    bills_to_receive: number
    pre_proposal: number
    proposal: number
    services: number
    clauses: number
    reports: number
    payments_edit: number
    proposal_unapproved_view: number
    access_edit_proposal_number: number
  }

}
interface OptionsProps {
  label: string
  value: string
}

const createFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  department: Yup.string().required('Departamento é obrigatório'),
  office: Yup.string().required('Cargo é obrigatório'),
  rg: Yup.string().required('RG é obrigatório'),
  cpf: Yup.string().required('CPF é obrigatório'),
  cep: Yup.string().required('CEP é obrigatório'),
  street: Yup.string().required('Rua é obrigatório'),
  city: Yup.string().required('Cidade é obrigatório'),
  state: Yup.string().required('Estado é obrigatório'),
  email: Yup.string().required('E-mail é obrigatório')
})

const optionsTypeAccess = [
  {
    value: '0',
    label: 'Não tem acesso'
  },
  {
    value: '1',
    label: 'Listagem'
  },
  {
    value: '2',
    label: 'Criação'
  },
  {
    value: '3',
    label: 'Acesso Total'
  }
]

const optionsDoubleTypeAccess = [
  {
    value: '0',
    label: 'Não tem acesso'
  },
  {
    value: '3',
    label: 'Acesso Total'
  }
]

const optionsGender = [
  {
    value: '0',
    label: 'Masculino'
  },
  {
    value: '1',
    label: 'Feminino'
  }
]

export const departmentOptions = [
  {
    value: 'levantamento',
    label: 'Levantamento'
  },
  {
    value: 'viabilidade',
    label: 'Viabilidade'
  },
  {
    value: 'aprovacao',
    label: 'Aprovação'
  },
  {
    value: 'financeiro',
    label: 'Financeiro'
  },
  {
    value: 'rh',
    label: 'RH'
  },
  {
    value: 'adm',
    label: 'Administrativo'
  }
]

export const departmentBossOptions = [
  {
    value: '0',
    label: 'Não'
  },
  {
    value: '1',
    label: 'Sim'
  }
]

export const officesOptions = [
  {
    value: 'sub-gerente-administrativo',
    label: 'Sub-Gerente Administrativo'
  },
  {
    value: 'sub-gerente-comercial',
    label: 'Sub-Gerente Comercial'
  },
  {
    value: 'motoboy',
    label: 'Motoboy'
  },
  {
    value: 'gerente-comercial',
    label: 'Gerente Comercial'
  },
  {
    value: 'gerente-adm-financeiro',
    label: 'Gerente Adm. Financeiro'
  },
  {
    value: 'auxiliar-de-limpeza',
    label: 'Auxiliar de Limpeza'
  },
  {
    value: 'auxiliar-de-financas',
    label: 'Auxiliar de Finanças'
  },
  {
    value: 'assistente-de-processo-senior',
    label: 'Assistente de Processos Sênior'
  },
  {
    value: 'assistente-de-financias-junior',
    label: 'Assistente de Finanças Júnior'
  },
  {
    value: 'analista-de-regularizacao-junior',
    label: 'Analista de Regularização Júnior'
  },
  {
    value: 'analista-de-processos-senior',
    label: 'Analistas de Processos Sênior'
  },
  {
    value: 'analista-de-processos-pleno',
    label: 'Analistas de Processos Pleno'
  },
  {
    value: 'analista-de-processos-junior',
    label: 'Analistas de Processos Júnior'
  }

]

const CollaboratorsCreate: FC = () => {
  const [loading, setLoading] = useState(false)
  const [optionsGym, setOptionsGym] = useState<OptionsProps[]>([])
  const [showAddressComponent, setShowAddressComponent] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false)
  const params = useParams()
  const location = useLocation()
  const toast = useToast()
  const navigate = useNavigate()
  const [files, setFiles] = useState<FilesProps[]>()
  const [initialCNHCover, setInitialCNHCover] = useState<undefined | string>(undefined)
  const [initialWorkWalletCover, setInitialWorkWalletCover] = useState<undefined | string>(undefined)

  const optionsTeams = [
    {
      label: 'Equipe 1',
      value: '1'
    },
    {
      label: 'Equipe 2',
      value: '2'
    },
    {
      label: 'Equipe 3',
      value: '3'
    },
    {
      label: 'Equipe 4',
      value: '4'
    },
    {
      label: 'Equipe 5',
      value: '5'
    }
  ]

  const DragCNHRef = useRef<DragAndDropExportedProps>(null)
  const DragWorkWalletRef = useRef<DragAndDropExportedProps>(null)

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createFormSchema)
  })
  const { errors } = formState

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      const {
        department,
        date_init,
        date_end,
        office,
        name,
        gender,
        born,
        rg,
        date_document,
        organ,
        cpf,
        reserve_doc,
        voter_doc,
        pis,
        work_doc,
        email,
        tel,
        phone,
        obs,
        cep,
        street,
        number,
        complement,
        neighborhood,
        city,
        state,
        bank,
        account,
        agency,
        pix,
        team,
        boss,
        access_nets,
        access_units,
        access_providers,
        access_collaborators,
        access_proccess,
        access_bills_to_pay,
        access_bills_to_receive,
        access_pre_proposal,
        access_proposal,
        access_services,
        access_clauses,
        access_reports,
        access_payments_edit,
        access_proposal_unapproved_view,
        access_edit_proposal_number
      } = values

      const normalizePhone = phone ? String(`${phone}`.replace(/([^0-9]+)/g, '')) : null
      const normalizeTel = tel ? String(`${tel}`.replace(/([^0-9]+)/g, '')) : null

      if (normalizePhone && normalizePhone.length < 11) {
        toast({
          title: 'Celular inválido',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      if (normalizeTel && normalizeTel.length < 10) {
        toast({
          title: 'Telefone inválido',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        return
      }

      const payload = {
        department,
        date_init,
        date_end,
        office,
        name,
        gender,
        born,
        rg,
        date_document,
        organ,
        cpf,
        reserve_doc,
        voter_doc,
        pis,
        work_doc,
        email,
        tel,
        phone,
        obs,
        cep,
        street,
        number,
        complement,
        neighborhood,
        city,
        state,
        bank,
        account,
        agency,
        pix,
        team,
        boss: boss || 0,
        access: {
          nets: access_nets,
          units: access_units,
          providers: access_providers,
          collaborators: access_collaborators,
          proccess: access_proccess,
          bills_to_pay: access_bills_to_pay,
          bills_to_receive: access_bills_to_receive,
          pre_proposal: access_pre_proposal,
          proposal: access_proposal,
          services: access_services,
          clauses: access_clauses,
          reports: access_reports,
          payments_edit: access_payments_edit,
          proposal_unapproved_view: access_proposal_unapproved_view,
          access_edit_proposal_number
        }
      }

      try {
        let id = params?.collaborator_id

        if (id) {
          await api.put(`/collaborators/${id}`, payload)
        } else {
          const { data } = await api.post('/collaborators', payload)
          id = data?.collaborator?.id
        }

        setLoading(false)

        const allFiles: Array<RefObject<DragAndDropExportedProps>> = [DragCNHRef, DragWorkWalletRef]

        const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

        await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
          const cb = index === normalizeInputsWithContent?.length - 1
            ? () => {
                navigate('/collaborators')
                setLoading(false)
              }
            : () => {}
          inputDrag?.current?.execute(`/files/${id}`, cb)
        }))

        if (!normalizeInputsWithContent?.length) {
          navigate('/collaborators')
          setLoading(false)
        }

        toast({
          title: `${params?.collaborator_id ? 'Editado' : 'Cadastrado'} com sucesso`,
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
      } catch (error) {
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        setLoading(false)
      }
    },
    [toast, navigate, params]
  )

  const handleSearchCep = useCallback(
    async (e: any) => {
      const cep = removeNonNumeric(e?.target?.value)

      if (Number(cep.length) === 8) {
        setLoading(true)

        try {
          const response = await api.get(`/cep/${cep}`)
          const { bairro, logradouro, uf, complemento, localidade }: CepProps =
            response.data

          setShowAddressComponent(true)

          setValue('street', logradouro)
          setValue('cep', cep)
          setValue('neighborhood', bairro)
          setValue('state', uf)
          setValue('city', localidade)

          if (complemento) {
            setValue('complement', complemento)
          }
        } catch (error) {
          console.log('error users cep: ', error)
          toast({
            title: error.response.data.message,
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
        } finally {
          setLoading(false)
        }
      }
    },
    [setValue, toast]
  )

  const loadItem = useCallback(async () => {
    try {
      const resp = await api.get(`/collaborators/${params?.collaborator_id}`)
      const data: CollaboratorProps = resp.data
      console.log('data: ', data)

      const bornNormalized = format(new Date(data.born), 'dd/MM/yyyy')
      const dateInitNormalized = format(new Date(data.date_init), 'dd/MM/yyyy')
      const dateEndNormalized = format(new Date(data.date_end), 'dd/MM/yyyy')
      const dateDocumentNormalized = format(new Date(data.date_document), 'dd/MM/yyyy')
      setValue('name', data.name)
      setValue('email', data.email)
      setValue('born', data.born ? bornNormalized : '')
      setValue('date_init', data.date_init ? dateInitNormalized : '')
      setValue('date_end', data.date_end ? dateEndNormalized : '')
      setValue('department', data.department)
      setValue('boss', data.boss)
      setValue('office', data.office)
      setValue('team', data.team)

      if (data.access) {
        setValue('access_nets', String(data?.access?.nets) || '0')
        setValue('access_units', String(data?.access?.units) || '0')
        setValue('access_providers', String(data?.access?.providers) || '0')
        setValue('access_collaborators', String(data?.access?.collaborators) || '0')
        setValue('access_proccess', String(data?.access?.proccess) || '0')
        setValue('access_bills_to_pay', String(data?.access?.bills_to_pay) || '0')
        setValue('access_bills_to_receive', String(data?.access?.bills_to_receive) || '0')
        setValue('access_pre_proposal', String(data?.access?.pre_proposal) || '0')
        setValue('access_proposal', String(data?.access?.proposal) || '0')
        setValue('access_services', String(data?.access?.services) || '0')
        setValue('access_clauses', String(data?.access?.clauses) || '0')
        setValue('access_reports', String(data?.access?.reports) || '0')
        setValue('access_payments_edit', String(data?.access?.payments_edit) || '0')
        setValue('access_proposal_unapproved_view', String(data?.access?.proposal_unapproved_view) || '0')
        setValue('access_edit_proposal_number', String(data?.access?.access_edit_proposal_number) || '0')
      }
      setValue(
        'tel',
        data.tel ? String(`${data.tel}`.replace(/([^0-9]+)/g, '')) : ''
      )

      setValue('cpf', data.cpf || '')
      setValue('reserve_doc', data.reserve_doc || '')
      setValue('organ', data.organ || '')
      setValue('rg', data.rg || '')
      setValue('gender', data.gender)
      setValue('reserve_doc', data.reserve_doc || '')
      setValue('voter_doc', data.voter_doc || '')
      setValue('pis', data.pis || '')
      setValue('work_doc', data.work_doc || '')
      setValue('obs', data.obs || '')

      setValue(
        'date_document',
        data.date_document ? dateDocumentNormalized : ''
      )

      if (data?.address?.cep) {
        setShowAddressComponent(true)
        setValue(
          'cep',
          String(`${data.address.cep}`.replace(/([^0-9]+)/g, ''))
        )
        setValue('street', data.address.street)
        setValue('number', data.address.number)
        if (data?.address?.complement) {
          setValue('complement', data.address.complement)
        }
        setValue('neighborhood', data.address.neighborhood)
        setValue('city', data.address.city)
        setValue('state', data.address.state)
      }

      if (data?.bank) {
        setValue('bank', data.bank.bank)
        setValue('account', data.bank.account)
        setValue('agency', data.bank.agency)
        setValue('pix', data.bank.pix)
      }

      setInitialCNHCover(data.files.find(i => i.type === 'cnh-ou-identidade')?.link)
      setInitialWorkWalletCover(data.files.find(i => i.type === 'carteira-de-trabalho')?.link)
      setFiles(data.files)
    } catch (error) {
      console.log('error edit: ', error)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, setValue, params])

  const handleResetPassword = useCallback(async () => {
    try {
      await api.patch(`/collaborators/password/${params?.collaborator_id}`)

      toast({
        title: 'Resetado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem(false)
      // load(page);
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem(false)
    }
  }, [toast, params])

  const closeModal = useCallback(() => {
    setSelectedItem(false)
  }, [])

  useEffect(() => {
    if (params?.collaborator_id) {
      loadItem()
    }
  }, [params])

  return (
    <Can permissions={['collaborators.create', 'collaborators.all']} page>
    <Box>
      <Header />
      {!!selectedItem && (
        <AlertDialogCustom
          title="Resetar Senha"
          isOpen={!!selectedItem}
          cancelFunction={closeModal}
          nextFunction={handleResetPassword}
        />
      )}
       {loading && <Loader />}
      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="wine.primary">
            Criar novo Colaborador
          </Heading>
          <Divider my="6" borderColor="wine.primary" />

          <Heading size="md" fontWeight="bold" color="gray.500" my="8">
            Dados Cadastrais
          </Heading>

          <VStack spacing={['6', '8']}>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                <Select
                  label="Departamento"
                  placeholder="Selecionar Departamento"
                  errors={errors.department}
                  {...register('department')}
                  options={departmentOptions}
                  />

<Select
                  label="Chefe de Setor"
                  placeholder="Selecionar Opção"
                  errors={errors.boss}
                  {...register('boss')}
                  options={departmentBossOptions}
                  />

            </SimpleGrid>

            <SimpleGrid spacing={['9', '9']} w="100%" minChildWidth="240px">
                <InputMaskCustom
                  type="text"
                  label="Data da contratação"
                  errors={errors.date_init}
                  {...register('date_init')}
                  placeholder="Digite data da contratação"
                  mask="99/99/9999"
                />

                <InputMaskCustom
                  type="text"
                  label="Data de desligamento"
                  errors={errors.date_end}
                  {...register('date_end')}
                  placeholder="Digite data de desligamento"
                  mask="99/99/9999"
                />

<Select
                  label="Equipe"
                  placeholder="Selecionar Equipe"
                  errors={errors.team}
                  {...register('team')}
                  options={optionsTeams}
                  />

                <Select
                  label="Cargo"
                  placeholder="Selecionar Cargo"
                  errors={errors.office}
                  {...register('office')}
                  options={officesOptions}
                />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                  label="Nome do Colaborador"
                  placeholder="Preencha o nome"
                  errors={errors.name}
                  {...register('name')}
              />

              <Select
                    label="Sexo"
                    placeholder="Selecionar"
                    errors={errors.gender}
                    {...register('gender')}
                    options={optionsGender}
                  />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                  <InputMaskCustom
                    type="text"
                    label="Data de nascimento"
                    errors={errors.born}
                    {...register('born')}
                    placeholder="Digite data de nascimento"
                    mask="99/99/9999"
                  />

                  <Input
                    label="RG"
                    placeholder="Preencha o RG"
                    errors={errors.rg}
                    {...register('rg')}
                  />

                  <InputMaskCustom
                    type="text"
                    label="Data de Emissão"
                    errors={errors.date_document}
                    {...register('date_document')}
                    placeholder="Digite data de Emissão"
                    mask="99/99/9999"
                  />

                  <Input
                    label="Orgão Expeditor"
                    placeholder="Preencha o Orgão Expeditor"
                    errors={errors.organ}
                    {...register('organ')}
                  />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  <InputMaskCustom
                    type="text"
                    label="CPF"
                    errors={errors.cpf}
                    {...register('cpf')}
                    placeholder="Digite o CPF"
                    mask="999.999.999-99"
                  />
                  <Input
                    label="Reservista"
                    placeholder="Preencha"
                    errors={errors.reserve_doc}
                    {...register('reserve_doc')}
                  />

                  <Input
                    label="Título de Eleitor"
                    placeholder="Preencha o Título"
                    errors={errors.voter_doc}
                    {...register('voter_doc')}
                  />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  <Input
                    label="PIS"
                    placeholder="Preencha o PIS"
                    errors={errors.pis}
                    {...register('pis')}
                  />

                  <Input
                    label="Número Carteira de Trabalho"
                    placeholder="Preencha o número"
                    errors={errors.work_doc}
                    {...register('work_doc')}
                  />
            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados de Endereço
          </Heading>

          <VStack spacing={['6', '8']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <InputMaskCustom
                type="text"
                label="CEP"
                errors={errors.cep}
                {...register('cep')}
                placeholder="Digite o CEP"
                mask="99999-999"
                onChange={handleSearchCep}
              />

              <Input
                label="Endereço"
                placeholder="Preencha o Endereço"
                errors={errors.street}
                {...register('street')}
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <Input
                label="Número"
                placeholder="Preencha o Número"
                errors={errors.number}
                {...register('number')}
              />

<Input
                label="Complemento"
                placeholder="Preencha o complemento"
                errors={errors.complement}
                {...register('complement')}
              />

              <Input
                label="Bairro"
                placeholder="Preencha o bairro"
                errors={errors.neighborhood}
                {...register('neighborhood')}
              />

<Input
                label="Cidade"
                placeholder="Preencha a Cidade"
                errors={errors.city}
                {...register('city')}
              />

<Input
                label="UF"
                placeholder="Preencha o UF"
                errors={errors.state}
                {...register('state')}
              />

            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados adicionais
          </Heading>

          <VStack spacing={['6', '9']}>
           <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <Input
                  label="Email"
                  placeholder="Preencha o email"
                  errors={errors.email}
                  {...register('email')}
                />
            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <InputMaskCustom
                type="text"
                label="Telefone"
                errors={errors.tel}
                {...register('tel')}
                placeholder="Digite o Telefone"
                mask="(99)99999-9999"
              />

              <InputMaskCustom
                type="text"
                label="Celular"
                errors={errors.phone}
                {...register('phone')}
                placeholder="Digite o celular"
                mask="(99)99999-9999"
              />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <TextArea
              label="Observações sobre o Colaborador"
              placeholder="Preencha as observações"
              errors={errors.obs}
              {...register('obs')}
            />

            </SimpleGrid>
          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Acesso aos Menus do Sistema
          </Heading>

          <VStack spacing={['6', '9']}>
            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                  <Select
                    label="Redes"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_nets')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

<Select
                    label="Unidades"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_units')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

<Select
                    label="Prestadores"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_providers')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

<Select
                    label="Colaboradores"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_collaborators')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                  <Select
                    label="Processos"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_proccess')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

<Select
                    label="Contas à Pagar"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_bills_to_pay')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

<Select
                    label="Contas à Receber"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_bills_to_receive')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

<Select
                    label="Pré-Propostas"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_pre_proposal')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

            </SimpleGrid>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
                  <Select
                    label="Propostas"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_proposal')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

<Select
                    label="Tipos de Serviços"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_services')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

<Select
                    label="Cláusulas de Proposta"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_clauses')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

<Select
                    label="Relatórios"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_reports')}
                    options={optionsTypeAccess}
                    defaultValue='0'
                  />

            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Acesso às Funcionalidades do sistema
          </Heading>

          <VStack>
          <SimpleGrid spacing={['9', '9']} w="100%" minChildWidth="240px">
                  <Select
                    label="Edição de pagamentos já efetuados"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_payments_edit')}
                    options={optionsDoubleTypeAccess}
                    defaultValue='0'
                  />

            </SimpleGrid>
          </VStack>

          <VStack mt="15px">
          <SimpleGrid spacing={['9', '9']} w="100%" minChildWidth="240px">
                  <Select
                    label="Visualização de proposta ainda não aprovada pelo chefe do setor"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_proposal_unapproved_view')}
                    options={optionsDoubleTypeAccess}
                    defaultValue='0'
                  />

            </SimpleGrid>
          </VStack>

          <VStack mt="15px">
          <SimpleGrid spacing={['9', '9']} w="100%" minChildWidth="240px">
                  <Select
                    label="Editar manualmente o número da proposta"
                    placeholder="Selecionar Acesso"
                    errors={errors.access}
                    {...register('access_edit_proposal_number')}
                    options={optionsDoubleTypeAccess}
                    defaultValue='0'
                  />

            </SimpleGrid>
          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Dados Bancários
          </Heading>

          <VStack spacing={['6', '9']}>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
              <Input
                label="Banco"
                placeholder="Preencha o banco"
                errors={errors.bank}
                {...register('bank')}
              />

            <Input
              label="Agência"
              placeholder="Preencha a agência"
              errors={errors.agency}
              {...register('agency')}
            />

            <Input
              label="Conta"
              placeholder="Preencha a conta"
              errors={errors.account}
              {...register('account')}
            />

            <Input
              label="Pix"
              placeholder="Preencha a chave pix"
              errors={errors.pix}
              {...register('pix')}
            />

            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
             Anexos
          </Heading>

          <VStack spacing={['6', '9']}>

            <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">
            <DragAndDrop
                ref={DragCNHRef}
                label="CNH ou Carteira de Identidade"
                containerStyle={{ width: '50%' }}
                payload={{
                  name: 'CNH ou Carteira de Identidade',
                  type: 'cnh-ou-identidade'
                }}
                defaultCover={initialCNHCover}
                id={files?.length ? files.find(i => i.type === 'cnh-ou-identidade')?.id : null}
              />

              <DragAndDrop
                ref={DragWorkWalletRef}
                label="Carteira de Trabalho"
                containerStyle={{ width: '50%' }}
                payload={{
                  name: 'Carteira de Trabalho',
                  type: 'carteira-de-trabalho'
                }}
                defaultCover={initialWorkWalletCover}
                id={files?.length ? files.find(i => i.type === 'carteira-de-trabalho')?.id : null}
              />

            </SimpleGrid>

          </VStack>

          <Heading size="md" fontWeight="bold" color="gray.500" my="8" >
              Informações Internas
          </Heading>

          <Flex >

          <Button
              width={200}
                as="button"
                size="sm"
                fontSize="sm"
                mr="2"
                colorScheme="teal"
                leftIcon={
                  <Icon as={BsLock} fontSize="16" />
                }
                onClick={() => setSelectedItem(true)}
              >
                Resetar senha
              </Button>

              <Button
                width={200}
                as="a"
                href={`/vacations/create/${params?.collaborator_id}`}
                size="sm"
                fontSize="sm"
                ml="2"
                colorScheme="blue"
                leftIcon={
                  <Icon as={FaUmbrellaBeach} fontSize="16" />
                }
              >
                Férias
              </Button>

          </Flex>

          <Flex mt={['6', '8']} justify="flex-end">
            <HStack>
              <Button
                as="a"
                bg="pink.500"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="wine.primary"
                _hover={{ bgColor: 'gray.500' }}
                color="white"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { CollaboratorsCreate }
