/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { RefObject, useMemo } from 'react'
import {
  Badge,
  Box, Button, Divider,
  Flex,
  Heading, Icon, Image, Modal, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack, useToast
} from '@chakra-ui/react'
import { Document, Page, pdfjs } from 'react-pdf'

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FiAlertTriangle, FiCheck, FiCheckCircle, FiX } from 'react-icons/fi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { DragAndDrop, DragAndDropExportedProps } from '../../Components/Form/DragAndDrop'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import IPaymentProps from './Interfaces/ProcessIntefaces'

import { Loader } from '../../Components/Loader'
import { FilesProps } from '../Process/Interfaces/ProcessIntefaces'
import { PaymentRefuseModal } from '../../Components/PaymentRefuseModal'
import { Can } from '../../Components/Can'
import { AccordionMultiPayments } from '../../Components/AccordionMultiPayments'
import { ModalMultiPayments } from '../../Components/ModalMultiPayments'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const statusOptions = [

  {
    value: 'REQUESTED',
    label: 'Aguardando Pagamento'
  },
  {
    value: 'PAID',
    label: 'Pago'
  },
  {
    value: 'REFUSED',
    label: 'Recusado'
  }
]

export const actionStatusOptions = [
  {
    value: 'REQUESTED',
    label: 'Selecionar'
  },
  {
    value: 'PAID',
    label: 'Efetuar Pagamento'
  },
  {
    value: 'REFUSED',
    label: 'Recusar Pagamento'
  }
]

export const actionRefundOptions = [
  {
    value: '',
    label: 'Selecionar'
  },
  {
    value: '1',
    label: 'Reembolso solicitado'
  },
  {
    value: '0',
    label: 'Marcar como não solicitado'
  }
]

export const typePaymentsOptions = {
  HONORARY: 'Honorários',
  TAX_NOT_REFUNDABLE: 'Taxa NÃO reembolsável',
  TAX_REFUNDABLE: 'Taxa reembolsável',
  REFUND_NOT_REFUNDABLE: 'Despesas Prestador NÃO reembolsável',
  REFUND_REFUNDABLE: 'Despesas Prestador reembolsável'
}

const TaxesType = ['TAX_REFUNDABLE', 'TAX_NOT_REFUNDABLE']

const BillsToPayCreate: FC = () => {
  const [loading, setLoading] = useState(false)

  const params = useParams()

  const toast = useToast()
  const navigation = useNavigate()
  const [paymentData, setPaymentData] = useState<IPaymentProps>({} as IPaymentProps)
  const [showPaidModal, setShowPaidModal] = useState(false)
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [showRefuseModal, setShowRefuseModal] = useState(false)
  const [initialCoverReceiptPayment, setInitialCoverReceiptPayment] = useState(undefined)
  const [receiptService, setReceiptService] = useState({} as FilesProps)
  const [showSharedModal, setShowSharedModal] = useState(false)
  const [files, setFiles] = useState<FilesProps[]>()

  const [initialCoverTax, setInitialCoverTax] = useState(undefined)
  const [initialCoverRefund, setInitialCoverRefund] = useState(undefined)

  const { register, handleSubmit, formState, setValue, getValues } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState

  const ReceiptPaymentRef = useRef<DragAndDropExportedProps>(null)

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {

    },
    [toast, params]
  )

  const load = useCallback(async () => {
    try {
      const { data } = await api.get(`/payments/${params?.id}`)
      setValue('status', data.statusLabel)

      const servicesTypes = ['comprovante-execucao-servico', 'comprovante-taxa', 'comprovante-reembolso']

      const findReceipt = data?.files?.find((i: FilesProps) => servicesTypes.includes(i.type))
      const findReceiptPayment = data?.files?.find((i: FilesProps) => i.type === 'comprovante-pagamento')

      if (findReceipt) {
        setReceiptService(findReceipt)
      }

      if (findReceiptPayment) {
        setInitialCoverReceiptPayment(findReceiptPayment?.link)
      }

      setPaymentData(data)
      setFiles(data.files)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast, params, setValue])

  useEffect(() => {
    load()
  }, [])

  const closeModal = useCallback(() => {
    setShowPaidModal(false)
  }, [])

  const handleConfirmPayment = useCallback(async () => {
    try {
      setLoading(true)
      const values = getValues()

      await api.put(`/payments/${params?.id}`, {
        status: values.status
      })

      toast({
        title: 'Pagamento Efetuado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })

      load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setShowPaidModal(false)
      setLoading(false)
    }
  }, [toast, params, getValues, load])

  const handleRefusePayment = useCallback(async (refused_reason: string) => {
    try {
      setLoading(true)

      await api.put(`/payments/${params?.id}`, {
        status: 'REFUSED',
        refused_reason
      })

      toast({
        title: 'Pagamento Recusado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })

      setShowRefuseModal(false)

      load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setShowPaidModal(false)
      setLoading(false)
    }
  }, [toast, params, load])

  const handleChangePaymentStatus = useCallback((e: any) => {
    const selectValue = e?.target?.value

    if (selectValue === 'PAID') {
      setShowPaidModal(true)
    }

    if (selectValue === 'REFUSED') {
      setShowRefuseModal(true)
    }
  }, [])

  const handleChangeRefundPayment = useCallback(async () => {
    try {
      const fields = getValues()
      console.log('fields: ', fields)
      const refundNewValue = fields?.refund_requested

      await api.patch(`/payments/refund/${params?.id}`, {}, {
        params: {
          status: refundNewValue === '1' ? 'YES' : 'NO'
        }
      })

      load()
      setShowRefundModal(false)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [getValues, params])

  const handleChangeRefundStatus = useCallback((e: any) => {
    setShowRefundModal(true)
  }, [])

  const MakeColor = (i: 'REQUESTED' | 'PAID' | 'REFUSED' | undefined) => {
    const colors = {
      REQUESTED: 'green',
      PAID: 'wine.500',
      REFUSED: 'red'
    }

    return i ? colors[i] : 'orange'
  }

  const MakeColorRefund = (refund: boolean) => {
    return refund ? 'green' : 'red'
  }

  const handleSubmitReceiptPayment = useCallback(async () => {
    try {
      if (!ReceiptPaymentRef?.current?.selectedFile) {
        toast({
          title: 'Anexo não encontrado',
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })

        return
      }

      setLoading(true)

      const allFiles: Array<RefObject<DragAndDropExportedProps>> = [ReceiptPaymentRef]

      const normalizeInputsWithContent: Array<RefObject<DragAndDropExportedProps>> = allFiles.filter(i => i.current?.selectedFile)

      console.log('normalizeInputsWithContent:', normalizeInputsWithContent)

      await Promise.all(normalizeInputsWithContent.map(async (inputDrag: RefObject<DragAndDropExportedProps>, index: number) => {
        const cb = index === normalizeInputsWithContent?.length - 1
          ? () => {
              toast({
                title: 'Comprovante salvo com sucesso',
                position: 'top',
                isClosable: true,
                status: 'success',
                variant: 'solid'
              })
              load()
              setLoading(false)
            }
          : () => {}
        inputDrag?.current?.execute(`/files/${paymentData?.payment_shared_id || params?.id}`, cb)
      }))
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })

      setLoading(false)
    }
  }, [toast, params, load, paymentData])

  const manyPayments = useMemo(() => {
    if (!paymentData?.id) return []

    if (paymentData?.providerOpenPayments?.length) {
      const normalize: any = { ...paymentData }

      delete normalize.providerOpenPayments

      return [normalize, ...paymentData?.providerOpenPayments]
    }

    return []
  }, [paymentData])

  const CustomTab = ({ children, badgeContent }: any): any => (
    <Tab>
      {children}
      {badgeContent && (
        <Badge ml={2} colorScheme="red">
          {badgeContent}
        </Badge>
      )}
    </Tab>
  )

  return (
    <Can permissions={['bills_to_pay.create', 'bills_to_pay.all']} page>
      {showSharedModal && <ModalMultiPayments refreshFunction={load} sharedPaymentId={String(paymentData.payment_shared_id)} onClose={() => setShowSharedModal(false)} /> }
    <Box>
      <Header />
      {loading && <Loader />}

      {showPaidModal && (
        <AlertDialogCustom
          title="Confirmar Pagamento"
          isOpen={showPaidModal}
          cancelFunction={closeModal}
          nextFunction={handleConfirmPayment}
        />
      )}

{showRefundModal && (
        <AlertDialogCustom
          title="Confirmar Ação"
          isOpen={showRefundModal}
          cancelFunction={() => setShowRefundModal(false)}
          nextFunction={handleChangeRefundPayment}
        />
)}

      {showRefuseModal && (
        <PaymentRefuseModal
          title="Recusar Pagamento"
          isOpen={showRefuseModal}
          cancelFunction={() => setShowRefuseModal(false)}
          nextFunction={handleRefusePayment}
        />
      )}

    <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
      <Sidebar />

        <Box
          as="form"
          onSubmit={handleSubmit(handleSubmitForm)}
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >

        <Tabs variant='enclosed'>
              <TabList>
              <CustomTab>Pagamento</CustomTab>
              {!!manyPayments?.length && paymentData.statusLabel === 'REQUESTED' && (
                <CustomTab badgeContent={manyPayments.length}>Outros Pagamentos</CustomTab>
              )}

              </TabList>

            <TabPanels>

            <TabPanel>

              <Flex justifyContent="space-between">
                <Heading size="lg" fontWeight="normal" color="wine.primary">
                  Valor: <b>{paymentData?.valueFormatted}</b>
                </Heading>
                <Box borderWidth={1} borderColor="wine.primary" padding={4} borderRadius={10}>
                  <Text color="wine.primary" fontWeight={500} align="left" >Pagamento Solicitado por:</Text>
                  <Text align="left">{paymentData?.collaborator?.name}</Text>
                  <Text align="left">Departamento: {paymentData?.collaborator?.department}</Text>
                </Box>

              </Flex>

              <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                      Dados Operacionais
              </Heading>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Nº Processo/OS</Text>
                    <Text align="center">{paymentData?.process?.cod_prefecture}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Nº Proposta</Text>
                    <Text align="center">{paymentData?.proposal?.number}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Rede</Text>
                    <Text align="center">{paymentData?.net?.name}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Unidade</Text>
                    <Text align="center">{paymentData?.unit?.id ? paymentData?.unit?.name || paymentData?.unit?.name_approvation || 'Nome da unidade não informado' : 'Unidade removida'}</Text>
                </Box>

              </SimpleGrid>

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

              <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Tipo</Text>
                  <Text align="center">{typePaymentsOptions[paymentData?.typeLabel]}</Text>
                  </Box>

                  <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Serviço</Text>
                  <Text align="center">{paymentData?.service?.name}</Text>
                  </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Valor</Text>
                    <Text align="center" fontWeight="bold">{paymentData?.valueFormatted}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Nome</Text>
                    <Text align="center">{paymentData?.provider?.name}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Parcela</Text>
                    <Text align="center">{paymentData?.installment}</Text>
                </Box>

                <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Verificado</Text>
                    <Text align="center">{paymentData?.provider?.active === 0 ? <Icon as={FiCheck} fontSize="24" color="#03ac13" /> : <Icon as={FiX} fontSize="24" color="#FF0000" />}</Text>
                </Box>

              </SimpleGrid>

              {paymentData?.obs && (
                <>
                    <Divider mt="10px"/>

                    <Text color="wine.primary" fontWeight={500} mt="20px">Observações</Text>
                    <Text >{paymentData?.obs}</Text>

                    </>
              )}

              <Divider style={{ marginTop: 20, marginBottom: 20 }} />

              <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

              <Box />
              <Box />
              <Box />
              </SimpleGrid>

              <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                      Anexos
                      <Text fontSize="13px" fontWeight={300}>
              Nota Fiscal / Comprovante de Execução / Boleto de Taxas / Comprovante para reembolso
              </Text>
              </Heading>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

                  {receiptService?.extension === 'pdf'
                    ? (
                      <a href={receiptService?.link} target="_blank" rel="noreferrer">
                        <div style={{ overflow: 'hidden', width: 200, height: 200 }}>
                          <Document file={receiptService.link}>
                            <Page pageNumber={1} height={200} width={200} />
                          </Document>
                        </div>
                      </a>
                      )
                    : <Box w={250}>
                        <a href={receiptService?.link} target="_blank" rel="noreferrer">
                        <Image src={receiptService?.link} width={250} mb={8}/></a>
                      </Box>}

              </SimpleGrid>

              <Divider />

              <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                    Status de Pagamento
              </Heading>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px" >

              <Box>
                    <Text color="wine.primary" fontWeight={500} >Estado atual</Text>
                    <Text fontWeight={700} fontSize={20} color={MakeColor(paymentData.statusLabel || undefined)}>{statusOptions?.find(i => i.value === paymentData.statusLabel)?.label }</Text>
              </Box>

                {paymentData?.refused_reason && <Box>
                    <Text color="wine.primary" fontWeight={500} >Motivo</Text>
                    <Text fontWeight={400} fontSize={16} >{paymentData?.refused_reason}</Text>
                </Box>}

                {paymentData.statusLabel === 'REQUESTED' && <Select label='Ação de Pagamento' {...register('status')} options={actionStatusOptions} defaultValue={paymentData.statusLabel} onChange={handleChangePaymentStatus} />
    }

              </SimpleGrid>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px" mt="12">
                {paymentData?.statusLabel === 'PAID' && (

                      <Box>
                      <DragAndDrop
                          ref={ReceiptPaymentRef}
                          label="Comprovante de pagamento"

                          payload={{
                            name: 'Comprovante de pagamento',
                            type: 'comprovante-pagamento'
                          }}
                          defaultCover={initialCoverReceiptPayment}
                          id={files?.length ? files.find(i => i.type === 'comprovante-pagamento')?.id : null}
                          saveFunction={initialCoverReceiptPayment ? undefined : async () => await handleSubmitReceiptPayment()}
                          extraCleanFunction={() => setInitialCoverReceiptPayment(undefined)}
                        />

                      </Box>

                )}
                  {paymentData?.payment_shared_id
                    ? (

                      <Flex mb="5" bg="teal" p="3" borderRadius="8px" alignItems="center" flexDirection="column" gap="30px">
                          <Icon as={FiAlertTriangle} fontSize="54" color="#fff" />
                          <Text color="white" fontWeight={500} w="300px" align="center" >Este pagamento foi compartilhado com o pagamento de outros serviços</Text>
                          <Button variant="outline" color="#fff" onClick={() => setShowSharedModal(true)}>Visualizar Todos</Button>
                      </Flex>

                      )
                    : (
                    <>
                    <Box />
                    <Box />
                    </>
                      )}

              </SimpleGrid>

              {(paymentData.typeLabel === 'REFUND_REFUNDABLE' || paymentData.typeLabel === 'TAX_REFUNDABLE') && (
                  <>
                    <Divider />

                    <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                          Status de Reembolso
                    </Heading>

                    <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px" >

                    <Box>
                          <Text color="wine.primary" fontWeight={500} >Estado atual</Text>
                          <Text fontWeight={700} fontSize={20} color={MakeColorRefund(!!(paymentData.refund_requested === 1))}>{paymentData.refund_requested === 1 ? 'Reembolso solicitado ao Cliente' : 'Reembolso ainda não solicitado'}</Text>
                    </Box>

                      {paymentData?.refused_reason && <Box>
                          <Text color="wine.primary" fontWeight={500} >Motivo</Text>
                          <Text fontWeight={400} fontSize={16} >{paymentData?.refused_reason}</Text>
                      </Box>}

                      <Select label='Ações' {...register('refund_requested')} options={actionRefundOptions}
                      // defaultValue={String(paymentData.refund_requested)}
                      onChange={handleChangeRefundStatus} />

                    </SimpleGrid>
                  </>
              )}

              <Divider style={{ marginTop: 40, marginBottom: 20 }}/>

              <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                      Dados do Pagamento
              </Heading>

              <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px" mb="15">

              {paymentData?.bar_code && <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Código de Barras</Text>
                    <Text align="center">{paymentData?.bar_code}</Text>
                </Box>}

                {paymentData?.bank?.pix && !paymentData?.bar_code && <Box>
                    <Text color="wine.primary" fontWeight={500} align="center">Chave Pix</Text>
                    <Text align="center">{paymentData?.bank?.pix}</Text>
                </Box>}

                {paymentData?.bank?.bank && !paymentData?.bar_code && (
                  <>
                    <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Banco</Text>
                      <Text align="center">{paymentData?.bank?.bank}</Text>
                    </Box>

                    <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Agência</Text>
                      <Text align="center">{paymentData?.bank?.agency}</Text>
                    </Box>

                    <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Conta</Text>
                      <Text align="center">{paymentData?.bank?.account}</Text>
                    </Box>

                  </>

                )}

              </SimpleGrid>

            </TabPanel>

            <TabPanel>
              {!!paymentData?.providerOpenPayments?.length && (
                <Flex mb="5" bg="teal" p="3" borderRadius="8px" alignItems="center">
                    <Icon as={FiAlertTriangle} fontSize="24" color="#fff" mr={3} />
                    <Text color="white" fontWeight={500} >Este prestador possui mais de um pagamento solicitado em aberto.</Text>
                </Flex>
              )}

                <AccordionMultiPayments data={manyPayments} refreshFunction={load} />

            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { BillsToPayCreate }
