/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useToast
} from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { format } from 'date-fns'
import queryString from 'query-string'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AiFillEye, AiOutlineDelete } from 'react-icons/ai'
import { RiAddLine } from 'react-icons/ri'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Can } from '../../Components/Can'
import { Input } from '../../Components/Form/Input'
import { Select } from '../../Components/Form/Select'
import { Header } from '../../Components/Header'
import { Loader } from '../../Components/Loader'
import { PaymentRefuseModal } from '../../Components/PaymentRefuseModal'
import { PaymentRequestModal } from '../../Components/PaymentRequestModal'
import { ReceiptPaymentModal } from '../../Components/ReceiptPaymentModal'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { PaymentsType } from '../BillsToPay'
import IProcessProps, { IEachPaymentProps, IPaymentStatus, IPrefecuturesProps } from './Interfaces/ProcessIntefaces'
import { ModalNewBill } from './ModalNewBill'
import { useAuth } from '../../Hooks/Auth'
import { validateUserPermissions } from '../../utils/validateUserPermissions'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { randomUUID } from 'crypto'
import { Checkbox } from '../../Components/Form/Checkbox'
import { TextArea } from '../../Components/Form/TextArea'
import { InputModalSearch } from '../../Components/InputModalSearch'
import { OptionsProps } from '../PreProposal/create'

const optionsPrefectures = [
  {
    value: 'SIMPROC',
    label: 'SIMPROC'
  },
  {
    value: 'SEI',
    label: 'SEI'
  },
  {
    value: 'DIARIO',
    label: 'DIARIO'
  }
]

interface ItemsSelected {
  id: string
  label: string
  value: string
}

export const allowedReceiptValues = ['PAID', 'REQUESTED', 'REFUSED', 'WAITING_PERMISSION']

const ProcessCreate: FC = () => {
  const [loading, setLoading] = useState(false)

  const { user } = useAuth()
  const params = useParams()
  const toast = useToast()
  const navigation = useNavigate()
  const [processData, setProcessData] = useState<IProcessProps>({} as IProcessProps)
  const [selectedPayment, setSelectedPayment] = useState<IEachPaymentProps>({} as IEachPaymentProps)
  const [receiptSelectedPayment, setReceiptSelectedPayment] = useState<IEachPaymentProps>({} as IEachPaymentProps)
  const [refusedPayment, setRefusedPayment] = useState<IEachPaymentProps>({} as IEachPaymentProps)
  const [selectedItem, setSelectedItem] = useState({} as IEachPaymentProps)
  const [selectedPrefecture, setSelectedPrefecture] = useState({} as IPrefecuturesProps)
  const [selectedMonitoring, setSelectedMonitoring] = useState(false)
  const [monitoring, setMonitoring] = useState(false)
  const [services, setServices] = useState<OptionsProps[]>([])
  const [showNewBillModal, setShowNewBillModal] = useState(false)
  const [billId, setBillId] = useState('')
  const location = useLocation()
  const parsed = queryString.parse(location.search)
  const [prefectures, setPrefectures] = useState<IPrefecuturesProps[]>([])
  const [servicesSelected, setServicesSelected] = useState<ItemsSelected[]>([])

  const { register, handleSubmit, formState, setValue, unregister } = useForm({
    // resolver: yupResolver(createUserFormSchema)
  })
  const { errors } = formState
  const FormRef = useRef<any>(null)

  const validateData = (data: any) => {
    const seenPrefectures = new Set() // Para verificar valores únicos no campo `prefecture`.

    for (const item of data) {
      // Verifica se todos os campos possuem valores
      for (const [key, value] of Object.entries(item)) {
        if (!value) {
          toast({
            title: 'Preencha todos os campos',
            position: 'top',
            isClosable: true,
            status: 'error',
            variant: 'solid'
          })
          return false
        }
      }

      // Verifica se o campo `prefecture` é único
      // if (seenPrefectures.has(item.prefecture)) {
      //   toast({
      //     title: 'Cada prefeitura pode ser cadastrada apenas uma vez',
      //     position: 'top',
      //     isClosable: true,
      //     status: 'error',
      //     variant: 'solid'
      //   })
      //   return false
      // }
      seenPrefectures.add(item.prefecture)
    }

    return true // Caso todos os checks passem
  }

  const handleUpdateObs: SubmitHandler<any> = useCallback(
    async (values) => {
      setLoading(true)
      try {
        const payload = {
          obs: values?.obs
        }

        await api.put(`/process/${params?.id}`, payload)

        toast({
          title: 'Processo atualizado',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
      } catch (error) {
        setLoading(false)
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setSelectedPayment({} as IEachPaymentProps)
      } finally {
        setLoading(false)
      }
    },
    [prefectures, params, toast]
  )

  const handleSubmitForm: SubmitHandler<any> = useCallback(
    async (values) => {
      if (!prefectures?.length) return
      setLoading(true)
      try {
        console.log('values: ', values)

        console.log('servicesSelected: ', servicesSelected)

        const data = prefectures?.map(i => {
          return {
            prefecture_code: values[`prefecture_code-${i.id}`] || '',
            prefecture: values[`prefecture-${i.id}`] || '',
            id: i.id,
            obs: values[`obs-${i.id}`] || '',
            service_id: servicesSelected?.find(item => item.id === i.id)?.value ?? '',
            automation: values[`automation-${i.id}`] === true ? 1 : 0
          }
        })

        console.log('data: ', JSON.stringify(data))

        await api.put(`/process-prefecture/${params?.id}`, { prefectures: data })

        toast({
          title: 'Prefeituras Cadastradas',
          position: 'top',
          isClosable: true,
          status: 'success',
          variant: 'solid'
        })
      } catch (error) {
        setLoading(false)
        toast({
          title: error.response.data.message,
          position: 'top',
          isClosable: true,
          status: 'error',
          variant: 'solid'
        })
        setSelectedPayment({} as IEachPaymentProps)
      } finally {
        setLoading(false)
      }
    },
    [prefectures, params, toast, servicesSelected]
  )

  const load = useCallback(async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`/process/${params?.id}`, {
        params: {
          paymentKind: 0
        }
      })

      const normalize = {
        ...data,
        payments_extra: data?.payments_extra?.map((i: any) => {
          return {
            ...i,
            created_at: format(new Date(i.created_at), 'dd/MM/yyyy HH:mm')
          }
        }),
        items: data?.items?.map((i: any) => {
          return {
            ...i,
            release_date: i?.release_date ? format(new Date(i?.release_date), 'dd/MM/yyyy') : 'Não informado'
          }
        })
      }

      setProcessData(normalize)

      // if (data?.obs) {
      //   setValue('obs', data?.obs)
      // }

      const getPrefectures = data?.prefectures?.map((i: any) => {
        if (i?.automation === 1) {
          setValue(`automation-${i?.id}`, true)
        }

        return {
          ...i,
          prefecture: i?.prefecture,
          prefecture_code: i?.prefecture_code,
          id: i.id,
          service_id: i?.service_id,
          obs: i?.obs,
          automation: i?.automation === 1
        }
      })

      const getInitialServices = data?.prefectures?.map((i: any) => {
        const payload = {
          id: i.id,
          label: i?.service?.name,
          value: i.service_id

        }

        return payload
      })

      setServicesSelected(getInitialServices)

      setPrefectures(getPrefectures)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [toast, params, setValue])

  useEffect(() => {
    load()
  }, [])

  const handlePayment = useCallback(async (obs?: string) => {
    const { id } = selectedPayment
    setLoading(true)
    try {
      const { data } = await api.patch(`/payments/${id}?kind=0`, { obs })

      const wasAgended = data?.payment?.allowed_at
      const payment_id = data?.payment?.id

      setSelectedPayment({} as IEachPaymentProps)

      return { payment_id, wasAgended }
    } catch (error) {
      setLoading(false)
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedPayment({} as IEachPaymentProps)
    }
  }, [selectedPayment, toast])

  const closeModal = useCallback(() => {
    setSelectedPayment({} as IEachPaymentProps)
  }, [])

  const makePaymentButtonColor = useCallback((statusLabel: IPaymentStatus, removed?: boolean) => {
    if (removed) {
      return 'black'
    }

    if (statusLabel === 'CREATED') {
      return 'pink.500'
    } else if (statusLabel === 'REQUESTED') {
      return 'teal.500'
    } else if (statusLabel === 'REFUSED') {
      return 'red.500'
    } else if (statusLabel === 'WAITING_PERMISSION') {
      return 'yellow.500'
    } else {
      return 'gray.500'
    }
  }, [])

  const makeLabelPaymentTaxOrRefund = useCallback((statusLabel: PaymentsType, date: string | null) => {
    if (statusLabel === 'TAX_NOT_REFUNDABLE') {
      return 'Taxa NÃO reembolsável'
    } else if (statusLabel === 'TAX_REFUNDABLE') {
      return 'Taxa reembonsável'
    } else if (statusLabel === 'REFUND_NOT_REFUNDABLE') {
      return 'Despesas Prestador NÃO reembolsável'
    } else {
      // REFUND_REFUNDABLE
      return 'Despesas Prestador reembolsável'
    }
  }, [])

  const makeLabelPayment = useCallback((statusLabel: Omit<IPaymentStatus, 'HONORARY'>, date: string | null, removed?: boolean) => {
    if (removed) {
      return 'Item Cancelado'
    }

    if (statusLabel === 'CREATED') {
      return 'Solicitar Pagamento'
    } else if (statusLabel === 'REQUESTED') {
      return 'Pagamento Solicitado'
    } else if (statusLabel === 'REFUSED') {
      return 'Pagamento Recusado'
    } else if (statusLabel === 'WAITING_PERMISSION') {
      return `Agendado: ${date ?? ''}`
    } else {
      return 'Pagamento efetuado'
    }
  }, [])

  const makeButtonFunction = useCallback((payment: IEachPaymentProps, type: 'tax-or-refund' | 'honorary', removed?: boolean) => {
    if (removed) {
      return () => setSelectedItem(payment)
    }

    if (payment.statusLabel === 'CREATED') {
      if (type === 'honorary') {
        return () => setSelectedPayment(payment)
      } else {
        return () => navigation(`/tax-or-refund/create/${params?.id}?id=${payment?.id}`)
      }
    }

    if (payment.statusLabel === 'REFUSED') {
      return () => setRefusedPayment(payment)
    }

    if (payment.statusLabel === 'REQUESTED') {
      if (type === 'honorary') {
        return () => setSelectedPayment(payment)
      } else {
        return () => navigation(`/tax-or-refund/create/${params?.id}?id=${payment?.id}`)
      }
    }

    if (payment.statusLabel === 'PAID') {
      const hasPermission = validateUserPermissions({ user, permissions: ['payments_edit.all'] })
      if (hasPermission) {
        return () => navigation(`/tax-or-refund/create/${params?.id}?id=${payment?.id}`)
      } else {
        return () => {}
      }
    }

    return () => {}
  }, [params, navigation, user])

  const handleReprocessPayment = useCallback(async () => {
    try {
      setLoading(true)

      await api.put(`/payments/${refusedPayment?.id}`, {
        status: 'CREATED'
      })

      toast({
        title: 'Pagamento Resetado',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })

      load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setRefusedPayment({} as IEachPaymentProps)
      setLoading(false)
    }
  }, [toast, load, refusedPayment])

  const hanleDelete = useCallback(async () => {
    const { id } = selectedItem
    try {
      await api.delete(`/payments/${id}`, {
        params: {
          revert: selectedItem.deleted_at ? true : null
        }
      })

      toast({
        title: selectedItem.deleted_at ? 'Reativado com sucesso' : 'Deletado com sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
      setSelectedItem({} as IEachPaymentProps)
      load()
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
      setSelectedItem({} as IEachPaymentProps)
    }
  }, [selectedItem, toast, load])

  const closeModalDeletePayment = useCallback(() => {
    setSelectedItem({} as IEachPaymentProps)
  }, [])

  const handleAddPrefecture = useCallback(() => {
    const payload: IPrefecuturesProps = {
      prefecture: '',
      prefecture_code: '',
      id: uuid(),
      automation: 0,
      obs: '',
      service_id: ''
    }

    setPrefectures(prev => [...prev, payload])
  }, [])

  const hanleDeletePrefecture = useCallback(async () => {
    setLoading(true)
    try {
      const filtered = prefectures?.filter(i => i.id !== selectedPrefecture?.id)

      setPrefectures([...filtered])
      setSelectedPrefecture({} as IPrefecuturesProps)

      await api.delete(`/process-prefecture/${selectedPrefecture?.id}`, {
        params: {
          paymentKind: 0
        }
      })

      setPrefectures([...filtered])
      setSelectedPrefecture({} as IPrefecuturesProps)

      toast({
        title: 'Prefeitura removida',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [selectedPrefecture, prefectures, toast])

  const handleActiveAutomation = useCallback(async () => {
    setLoading(true)
    try {
      await api.patch(`/process/${params?.id}`, {
        monitoring: monitoring ? 1 : 0
      })

      setSelectedMonitoring(false)

      toast({
        title: 'Sucesso',
        position: 'top',
        isClosable: true,
        status: 'success',
        variant: 'solid'
      })
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    } finally {
      setLoading(false)
    }
  }, [monitoring, toast, params])

  const handleSearchServices = useCallback(async (e: string) => {
    try {
      const { data } = await api.get('/services', {
        params: {
          pag: 1,
          all: true,
          name: e
        }
      })

      const normalize = data?.data?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })
      setServices(normalize)
    } catch (error) {
      toast({
        title: error.response.data.message,
        position: 'top',
        isClosable: true,
        status: 'error',
        variant: 'solid'
      })
    }
  }, [toast])

  const handleSelectService = useCallback((service_id: string, item_id: string) => {
    const service = services.find(i => i.value === service_id)

    if (service) {
      // setServiceSelected(service)
      const filtered = servicesSelected.filter(p => p.id !== item_id)

      const payload = {
        id: item_id,
        label: service.label,
        value: service.value

      }
      setServicesSelected([...filtered, payload])
    }

    // alterando as opções de pagamentos

    // pegando todas as opcoes deste servico
    // const serviceWithAllPayments = servicesWithPayments.find(i => i.id === service_id)

    // if (serviceWithAllPayments) {
    //   // normatizando os dados para o select
    //   const normalize = []

    //   for (let i = 1; i <= serviceWithAllPayments.installments; i++) {
    //     normalize.push({
    //       value: String(i),
    //       label: String(i)
    //     })
    //   }

    //   const payload = {
    //     id: item_id,
    //     options: normalize
    //   }

    //   const filtered = paymentsOptions.filter(p => p.id !== item_id)

    //   setPaymentsOptions([...filtered, payload])
    // }
  }, [services, servicesSelected])

  return (
    <Can permissions={['proccess.list', 'proccess.create', 'proccess.all']} page>
       {showNewBillModal && <ModalNewBill isOpen={showNewBillModal} id={billId} title="Solicitação de Pagamento Avulso" cancelFunction={() => {
         setBillId('')
         setShowNewBillModal(false)
       }} nextFunction={() => {
         setShowNewBillModal(false)
         load()
       }} />}
    <Box>

      <Header />

      {selectedMonitoring && (
        <AlertDialogCustom
          title=''
          isOpen={!!selectedMonitoring}
          cancelFunction={() => {
            setSelectedMonitoring(false)
            // setMonitoring(prev => !prev)
          }}
          nextFunction={handleActiveAutomation}
        />
      )}

      {!!selectedPrefecture?.id && (
        <AlertDialogCustom
          title='Remover Prefeitura'
          isOpen={!!selectedPrefecture?.id}
          cancelFunction={() => setSelectedPrefecture({} as IPrefecuturesProps)}
          nextFunction={hanleDeletePrefecture}
        />
      )}

      {!!selectedItem?.id && (
        <AlertDialogCustom
          title={selectedItem.deleted_at ? 'Reativar Pagamento' : 'Cancelar Pagamento'}
          isOpen={!!selectedItem?.id}
          cancelFunction={closeModalDeletePayment}
          nextFunction={hanleDelete}
        />
      )}

      {
        !!selectedPayment?.id && (
        <PaymentRequestModal
          title={selectedPayment?.statusLabel === 'REQUESTED' ? 'Atualizar Solicitação' : 'Solicitar Pagamento'}
          isOpen={!!selectedPayment?.id}
          cancelFunction={closeModal}
          nextFunction={handlePayment}
          cbFunction={async () => {
            await load()
            setLoading(false)
          }}
          type={selectedPayment?.statusLabel}
          payment={selectedPayment}
        />
        )
      }

      {
        !!receiptSelectedPayment?.id && (
          <ReceiptPaymentModal
          title="Recibos"
          isOpen={!!receiptSelectedPayment?.id}
          cancelFunction={() => setReceiptSelectedPayment({} as IEachPaymentProps)}
          nextFunction={() => {}}
          cbFunction={async () => await load()}
          files={receiptSelectedPayment.files}
          />
        )
      }

      {!!refusedPayment?.id && (
        <PaymentRefuseModal
          title="Pagamento Recusado"
          isOpen={!!refusedPayment?.id}
          cancelFunction={() => setRefusedPayment({} as IEachPaymentProps)}
          nextFunction={handleReprocessPayment}
          refused
          initialRefusedReason={refusedPayment?.refused_reason}
        />
      )}

      <Flex w="100%" my="6" maxWidth={1480} mx="auto" px="6">
        <Sidebar />

        {loading ? <Loader /> : (
          <Box

          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
        >
          <Heading size="lg" fontWeight="normal" color="wine.primary">
            Processos
          </Heading>

          <Tabs variant='enclosed' mt="10" defaultIndex={Number(parsed?.tab) || 0}>
            <TabList>
              <Tab>Proposta e Pagamentos</Tab>
              <Tab>Taxas e Reembolsos</Tab>
              <Tab>Processo</Tab>
            </TabList>
            <TabPanels>

              <TabPanel>

                <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                  Dados Cadastrais
                </Heading>

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Responsável Técnico</Text>
                      <Text align="center">{processData?.technical?.name}</Text>
                  </Box>

                  <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Responsável Financeiro</Text>
                      <Text align="center">{processData?.financial?.name}</Text>
                  </Box>

                  <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Rede</Text>
                      <Text align="center">{processData?.net?.name}</Text>
                  </Box>

                </SimpleGrid>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px">

                  <Box>
                      <Text color="wine.primary" fontWeight={500} align="center">Unidade</Text>
                      <Text align="center">{processData?.unit?.name}</Text>
                  </Box>

                  <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Área Total</Text>
                  <Text align="center">{processData?.immobile?.area_ground || '--'}</Text>
                </Box>

                <Box>
                  <Text color="wine.primary" fontWeight={500} align="center">Área Construída</Text>
                  <Text align="center">{processData?.immobile?.area_building || '--'}</Text>
                </Box>

                </SimpleGrid>

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                  Itens da Proposta
                </Heading>

                {processData?.items?.map((item, indexItem: number) => (
                    <VStack spacing={['9', '9']} mb="5" bg={'wine.primary'} p="10" key={item.id} borderRadius="8px">

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">
                          <Box>
                            <Text color="white.primary" fontWeight={500} align="left">Serviço</Text>
                            <Text align="left" color="white.primary">{item?.service?.name}</Text>
                          </Box>
                      </SimpleGrid>

                      <Divider color="white" />

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="200px">

                          <Box>
                            <Text color="white.primary" fontWeight={500} align="center">Executante</Text>
                            <Text align="center" color="white.primary">{item?.executor?.name}</Text>
                          </Box>

                          <Box>
                            <Text color="white.primary" fontWeight={500} align="center">Parcelamento</Text>
                            <Text align="center" color="white.primary">{item?.installments}x</Text>
                          </Box>

                          <Box>
                            <Text color="white.primary" fontWeight={500} align="center">Valor Total</Text>
                            <Text align="center" color="white.primary">{item?.executor_value_formatted}</Text>
                          </Box>

                          <Box>
                         <Text color="white.primary" fontWeight={500} align="center">Previsão de Entrega</Text>
                         <Text align="center" color="white.primary">{item?.release_date}</Text>
                       </Box>

                      </SimpleGrid>

                      <Divider />
                        <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px">

                        <Box>
                              <Text color="white.primary" fontWeight={500} align="left">Observações</Text>
                              <Text align="left" color="white.primary">{item?.obs || '--'}</Text>
                            </Box>

                      </SimpleGrid>

                        <Divider color="white" />

                        {processData?.payments[item.id]?.map(payment => (
                          <SimpleGrid spacing={['12', '12']} w="100%" minChildWidth="240px" key={payment.id}>

                            <Box>
                              <Text color="white.primary" fontWeight={500} align="center">Parcela {payment.installment}: {payment.provider_value_installment_formatted}</Text>

                            </Box>

                            {allowedReceiptValues.includes(payment.statusLabel) && !payment.deleted_at
                              ? (
                              <Button
                                type="button"
                                onClick={() => setReceiptSelectedPayment(payment)}
                                bg="blue.400"
                                _hover={{ bgColor: 'gray.500' }}
                                color="white"
                                leftIcon={<Icon as={AiFillEye} fontSize="19" />}

                              >
                                Recibos
                            </Button>
                                )
                              : <Box />}

                            <Flex>
                              <Button
                                  type="button"
                                  onClick={makeButtonFunction(payment, 'honorary', !!payment.deleted_at)}
                                  bg={makePaymentButtonColor(payment.statusLabel, !!payment.deleted_at)}
                                  _hover={{ bgColor: 'gray.500' }}
                                  color="white"
                                  minWidth="250px"
                                  marginRight="10px"

                                >
                                  {makeLabelPayment(payment.statusLabel, payment.allowed_at_formatted, !!payment.deleted_at)}
                              </Button>
                              {!payment.deleted_at && (
                                <IconButton
                                colorScheme="red"
                                aria-label="Edit Content"
                                icon={<Icon as={AiOutlineDelete} size="30" />}
                                onClick={() => setSelectedItem(payment)}

                              />
                              )}

                            </Flex>
                          </SimpleGrid>
                        ))}

                    </VStack>
                ))}
              </TabPanel>

              <TabPanel>
                <Flex justifyContent="flex-end">
                <Button
                    // onClick={() => setShowNewBillModal(true)}

                    as="a"
                    size="sm"
                    fontSize="sm"
                                href={`/tax-or-refund/create/${params?.id}`}
                    bg="wine.200"
                    colorScheme="wine"
                    ml="auto"
                    leftIcon={
                      <Icon as={RiAddLine} fontSize="16" />
                    }
                  >
                  Adicionar nova conta avulsa
                </Button>
                </Flex>

                <Table colorScheme="blue" mt="25px">
                <Thead>
                  <Tr>

                    <Th textAlign="center" fontSize={12}>Tipo</Th>
                    <Th textAlign="center" fontSize={12}>Valor</Th>

                    <Th textAlign="center" fontSize={12}>Data</Th>
                    <Th textAlign="center" fontSize={12}>Ações</Th>
                    {/* <Th textAlign="center" fontSize={12}>Status</Th> */}

                    <Th w="8"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {processData?.payments_extra?.map((i) => (
                    <Tr key={i.id}>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize={14}>
                            <Text fontWeight="bold">{makeLabelPaymentTaxOrRefund(i.typeLabel, i.allowed_at)}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize={14}>
                            <Text fontWeight="bold">{i.fee_refund_value_formatted}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" fontSize={14}>
                            <Text fontWeight="bold" textAlign="center">{i?.created_at}</Text>
                          </Link>
                      </Td>

                      <Td>

                            {allowedReceiptValues.includes(i.statusLabel)
                              ? (
                              <Button
                                type="button"
                                onClick={() => setReceiptSelectedPayment(i)}
                                bg="blue.400"
                                _hover={{ bgColor: 'gray.500' }}
                                color="white"
                                leftIcon={<Icon as={AiFillEye} fontSize="19" />}
                                style={{ marginRight: 10 }}

                              >
                                Recibos
                            </Button>
                                )
                              : <Box />}

                            <Button
                                type="button"
                                 onClick={makeButtonFunction(i, 'tax-or-refund')}
                                // onClick={() => console.log('item: ', i)}
                                bg={makePaymentButtonColor(i.statusLabel)}
                                _hover={{ bgColor: 'gray.500' }}
                                color="white"

                              >
                                {makeLabelPayment(i.statusLabel, i.allowed_at_formatted)}
                            </Button>

                              {/* <Button

                                as="button"
                                size="sm"
                                fontSize="sm"
                                onClick={() => handleOpenTaxOrRefund(i?.id)}
                                bg="wine.200"
                                colorScheme="wine"
                                leftIcon={
                                  <Icon as={RiEye2Fill} fontSize="16" />
                                }
                              >
                              Visualizar
                            </Button> */}
                      </Td>

                    </Tr>
                  ))}
                </Tbody>
              </Table>

              </TabPanel>

              <TabPanel>
                  {/* <Box
                    as="form"
                    onSubmit={handleSubmit(handleUpdateObs)}
                    ref={FormRef}
                    flex="1"
                  >

                  <TextArea
                                label="Observações"
                                placeholder="Preencha uma observação"
                                defaultValue={processData?.obs}
                                {...register('obs')}
                              />

                  <Button
                    type="submit"
                    bg="wine.primary"
                    _hover={{ bgColor: 'gray.500' }}
                    color="white"
                    isLoading={formState.isSubmitting}
                    mt="10"
                  >
                    Salvar
                  </Button>

                    </Box> */}

              <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                        ACOMPANHAMENTO NAS PREFEITURAS
                  </Heading>
                <Flex justifyContent='flex-end' alignItems='center'>
                  {/* <Checkbox colorScheme="pink" {...register('prefecture-action')} defaultChecked={processData?.automation === 1} onChange={(e: any) => {
                    setMonitoring(e?.target?.checked)
                    setSelectedMonitoring(true)
                  }} label='Monitorar Processo' /> */}

                  <Button
                    onClick={handleAddPrefecture}
                    bg="teal"
                    w='210px'
                    color="white"
                    isLoading={formState.isSubmitting}

                    >
                      Adicionar Prefeitura
                    </Button>
                </Flex>
                  <Box
                    as="form"
                    onSubmit={handleSubmit(handleSubmitForm)}
                    ref={FormRef}
                    flex="1"
                  >

                    {prefectures?.map((i, index) => (
                      <Box key={i.id} border='solid 1px' borderRadius='15px' padding='10' mt="10">

                      <Flex justifyContent='space-between' alignItems='center'>
                        <Checkbox colorScheme="pink"
                        style={{ marginBottom: '30px' }}
                            {...register(`automation-${i.id}`)}
                              defaultChecked={i?.automation === 1 || !!i?.automation}
                              // onChange={(e: any) => {
                              //   setMonitoring(e?.target?.checked)
                              //   setSelectedMonitoring(true)
                              // }}
                          label='Monitorar Processo'
                        />

                        <Button
                          onClick={() => setSelectedPrefecture(i)}
                          bg="black"
                          w='200px'
                          marginTop='-20px'
                          color="white"
                          isLoading={formState.isSubmitting}

                          >
                            Excluir Prefeitura
                          </Button>
                      </Flex>

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" style={{ marginTop: '20px', marginBottom: '30px' }}>
                          <InputModalSearch title="Serviço" searchFunction={handleSearchServices} data={services} extraFunction={(e: any) => handleSelectService(e, i.id)} placeholderButton={servicesSelected?.find(item => item.id === i.id) ? 'Trocar Serviço' : 'Selecionar Serviço'} btnStyle={{ backgroundColor: '#F765a3' }} />
                          <Input {...register(`service_id-${i.id}`)} value={servicesSelected?.find(item => item.id === i.id)?.id} isReadOnly visibility="hidden" />

                          {servicesSelected?.find(item => item.id === i.id) && (
                            <Box>
                            <Text fontWeight={500} align="center">Serviço</Text>
                            <Text align="center" >{servicesSelected?.find(item => item.id === i.id)?.label}</Text>
                          </Box>
                          )}

                      </SimpleGrid>

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" >
                          <Input
                            label="Número do processo na Prefeitura"
                            placeholder="Preenchar o número"
                            // errors={errors.name}
                            {...register(`prefecture_code-${i.id}`)}
                            defaultValue={i?.prefecture_code}
                          />

                            <Select
                              label="Selecionar Prefeitura"
                              placeholder="Selecionar"
                              // errors={errors.gender}
                              {...register(`prefecture-${i.id}`)}
                              options={optionsPrefectures}
                              defaultValue={i?.prefecture}
                            />
                      </SimpleGrid>

                      <SimpleGrid spacing={['6', '9']} w="100%" minChildWidth="240px" style={{ marginTop: '20px' }}>
                        <TextArea
                          label="Observações"
                          placeholder="Preencha uma observação"
                          defaultValue={processData?.obs}
                          {...register(`obs-${i.id}`)}
                        />
                      </SimpleGrid>

                        <Heading size="md" fontWeight="bold" color="gray.500" my="8">
                              HISTÓRICO DE CONSULTAS
                        </Heading>

                        {i?.prefecture ? (
                           <Tabs variant='enclosed' mt="10" defaultIndex={Number(parsed?.tab) || 0}>
                           <TabList>
                           <Tab>{i.prefecture}</Tab>

                           </TabList>

                           <TabPanels>

                               {i?.prefecture === 'SIMPROC' && (
                                  <TabPanel key={i.id}>
                                  <Heading size="sm" fontWeight="bold" color="gray.500" my="8">
                                    DADOS DO PROCESSO
                                  </Heading>

                                  {i?.history?.last_history && (
                                    <Box key={`${i.prefecture}-dados`} gap="15px">
                                      <Text fontWeight={700} fontSize="18px">Assunto</Text>
                                      <Text mb="10px">{i?.history?.last_history?.process?.subject}</Text>

                                      <Text fontWeight={700} fontSize="18px">Subassunto</Text>
                                      <Text mb="10px">{i?.history?.last_history?.process?.subSubject}</Text>

                                      <Text fontWeight={700} fontSize="18px">Motivo da Autuação</Text>
                                      <Text mb="10px">{i?.history?.last_history?.process?.reason}</Text>

                                      <Text fontWeight={700} fontSize="18px">Data da Autuação</Text>
                                      <Text mb="10px">{i?.history?.last_history?.process?.date}</Text>

                                      <Text fontWeight={700} fontSize="18px">Tipo de processo</Text>
                                      <Text>{i?.history?.last_history?.process?.type}</Text>
                                    </Box>
                                  )}

                                  <Divider my="8" />

                                  <Heading size="sm" fontWeight="bold" color="gray.500" my="8">
                                    INTERESSADO
                                  </Heading>

                                  {i?.history?.last_history && (
                                    <Box key={`${i.prefecture}-interessado`} gap="15px">
                                      <Text fontWeight={700} fontSize="18px">Nome</Text>
                                      <Text mb="10px">{i?.history?.last_history?.customer?.name}</Text>

                                      <Text fontWeight={700} fontSize="18px">SQL</Text>
                                      <Text mb="10px">{i?.history?.last_history?.customer?.sql}</Text>
                                    </Box>
                                  )}

                                  <Divider my="8" />

                                  <Heading size="sm" fontWeight="bold" color="gray.500" my="8">
                                    ESTE PROCESSO ESTÁ SOB RESPONSABILIDADE DA UNIDADE:
                                  </Heading>

                                  {i?.history?.last_history && (
                                    <Box key={`${i.prefecture}-responsabilidade`} gap="15px">
                                      <Text fontWeight={700} fontSize="18px">Sigla</Text>
                                      <Text mb="10px">{i?.history?.last_history?.unity?.sign}</Text>

                                      <Text fontWeight={700} fontSize="18px">Nome Completo</Text>
                                      <Text mb="10px">{i?.history?.last_history?.unity?.name}</Text>

                                      <Text fontWeight={700} fontSize="18px">Órgão Superior</Text>
                                      <Text mb="10px">{i?.history?.last_history?.unity?.secretary}</Text>

                                      <Text fontWeight={700} fontSize="18px">Data de recebimento</Text>
                                      <Text mb="10px">{i?.history?.last_history?.unity?.date}</Text>
                                    </Box>
                                  )}

                                  <Divider my="8" />

                                  <Heading size="sm" fontWeight="bold" color="gray.500" my="8">
                                    DECISÕES
                                  </Heading>

                                  {i?.history?.last_history && (
                                    <Table colorScheme="blue">
                                      <Thead>
                                        <Tr>
                                          <Th textAlign="center">Detalhes do Despacho</Th>
                                          <Th textAlign="center">Data de Cadastramento</Th>
                                          <Th textAlign="center">Data da Publicação</Th>
                                          <Th textAlign="center">Situação do Despacho</Th>
                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        {i?.history?.last_history?.decisions?.items?.map((item: any, index: number) => (
                                          <Tr key={`${i.id}-${index}`}>
                                            <Td>
                                              <Text fontWeight="bold" textAlign="center">{item?.details}</Text>
                                            </Td>
                                            <Td>
                                              <Text fontWeight="bold" textAlign="center">{item?.register_date}</Text>
                                            </Td>
                                            <Td>
                                              <Text fontWeight="bold" textAlign="center">{item?.publish_date}</Text>
                                            </Td>
                                            <Td>
                                              <Text fontWeight="bold" textAlign="center">{item?.situation}</Text>
                                            </Td>
                                          </Tr>
                                        ))}
                                      </Tbody>
                                    </Table>
                                  )}
                                  </TabPanel>
                               )}

                                {i?.prefecture === 'SEI' && (
                                  <TabPanel key={i.id}>

                                  <Heading size="sm" fontWeight="bold" color="gray.500" my="8">
                                    AUTUAÇÃO
                                  </Heading>
                                  {console.log('i?.history?.last_history: ', i)}

                                  {i?.history?.last_history && (
                                    <Box key={`${i.prefecture}-${Math.random()}`} gap="15px">
                                      <Text fontWeight={700} fontSize="18px">Processo</Text>
                                      <Text mb="10px">{i?.history?.last_history?.process_number}</Text>

                                      <Text fontWeight={700} fontSize="18px">Tipo</Text>
                                      <Text mb="10px">{i?.history?.last_history?.type}</Text>

                                      <Text fontWeight={700} fontSize="18px">Data de registro</Text>
                                      <Text mb="10px">{i?.history?.last_history?.date}</Text>
                                    </Box>
                                  )}

                                  <Heading size="sm" fontWeight="bold" color="gray.500" my="8">
                                    LISTA DE ANDAMENTOS
                                  </Heading>

                                  {i?.history?.last_history && (
                                    <Table colorScheme="blue">
                                      <Thead>
                                        <Tr>
                                          <Th textAlign="center">Data/Hora</Th>
                                          <Th textAlign="center">Unidade</Th>
                                          <Th textAlign="center">Descrição</Th>
                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        {i?.history?.last_history?.announcements?.items?.map((item: any, index: number) => (
                                          <Tr key={`${i.id}-${index}`}>
                                            <Td>
                                              <Text fontWeight="bold" textAlign="center">{item?.date}</Text>
                                            </Td>
                                            <Td>
                                              <Text fontWeight="bold" textAlign="center">{item?.unity}</Text>
                                            </Td>
                                            <Td>
                                              <Text fontWeight="bold" textAlign="center">{item?.description}</Text>
                                            </Td>

                                          </Tr>
                                        ))}
                                      </Tbody>
                                    </Table>
                                  )}

                                  </TabPanel>
                                )}

                               {i?.prefecture === 'DIARIO' && (
                                <TabPanel key={i.id}>

                                  {i?.history?.last_history && (
                                    <Box key={`${i.prefecture}-${Math.random()}`} gap="15px">
                                      <Text fontWeight={700} fontSize="18px">Última Atualização</Text>
                                      <Text mb="10px">{i?.history?.last_history?.description}</Text>

                                    </Box>
                                  )}
                                </TabPanel>
                               )}

                           </TabPanels>

                       </Tabs>
                        ) : <Text>Nenhum processo em prefeitura cadastrado</Text>}

                      </Box>

                    ))}

                <Button
                  type="submit"
                  bg="wine.primary"
                  _hover={{ bgColor: 'gray.500' }}
                  color="white"
                  isLoading={formState.isSubmitting}
                  mt="10"
                  >
                    Salvar
                  </Button>

                  </Box>

              </TabPanel>

            </TabPanels>
          </Tabs>

        </Box>
        )}

      </Flex>
    </Box>
    </Can>
  )
}

export { ProcessCreate }
