/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { AiOutlineDelete, AiOutlineUser } from 'react-icons/ai'
import { RiAddLine, RiEye2Fill, RiPencilLine } from 'react-icons/ri'
import { AlertDialogCustom } from '../../Components/AlertDialog'
import { Header } from '../../Components/Header'
import { SearchBox } from '../../Components/Header/SearchBox'
import { Pagination } from '../../Components/Pagination'
import { Sidebar } from '../../Components/Sidebar'
import api from '../../Services/api'
import { format } from 'date-fns'
import { Can } from '../../Components/Can'
import { typePaymentsOptions } from './create'
import { PAYMENTS_REFUND_REQUESTED } from '../../Services/contants'
import debounce from 'lodash.debounce'

type PaymentsStatus = 'REQUESTED' | 'PAID' | 'REFUSED'
export type PaymentsType = 'HONORARY' | 'TAX_NOT_REFUNDABLE' | 'REFUND_NOT_REFUNDABLE' | 'TAX_REFUNDABLE' | 'REFUND_REFUNDABLE'

interface Payments {
  id: string
  value: string
  installment: string
  created_at: string
  payment_day: string
  allowed_at: string | null
  value_formatted: string
  typeLabel: PaymentsType
  statusLabel: PaymentsStatus
  refund_requested: number
  process: {
    id: string
    code: string
  }
  provider: {
    id: string
    name: string
  }
  collaborator: {
    id: string
    name: string
    department: string
  }
  proposal: {
    id: string
    number: string
  }
  service: {
    id: string
    name: string
  }
  unit: {
    id?: string
    name?: string
    name_approvation?: string
  }
}

const BillsToPayList: FC = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [data, setData] = useState<Payments[]>([])
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(10)
  const [selectedItem, setSelectedItem] = useState<Payments>({} as Payments)
  const toast = useToast()

  const STATUS = {
    REQUESTED: 'Solicitado',
    PAID: 'Pago',
    REFUSED: 'Recusado'
  }

  const isWideScreen = useBreakpointValue({
    base: false,
    lg: true
  })

  const load = useCallback(async (pag: number) => {
    setLoading(true)
    try {
      const { data } = await api.get('/payments', {
        params: {
          pag,
          status: [1, 2, 3, 4],
          paymentKind: 0 // contas à pagar
        }
      })

      console.log('data: ', data.data)

      setPage(pag)
      setData(data.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar administradores: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
    // setData(fakeData)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load(page)
  }, [page])

  const makeBg = useCallback((status: PaymentsStatus, refund: number) => {
    if (refund === PAYMENTS_REFUND_REQUESTED.YES) {
      return '#899499'
    }
    switch (status) {
      case status = 'REQUESTED':
        return '#f8de7e'

      case status = 'PAID':
        return '#5dbb63'

      case status = 'REFUSED':
        return '#fa8072'

      default:
        return '#528fde'
    }
  }, [])

  const makeColor = useCallback((status: PaymentsStatus, refund: number) => {
    if (refund === PAYMENTS_REFUND_REQUESTED.YES) {
      return '#fff'
    }
    switch (status) {
      case status = 'REQUESTED':
        return '#993b58'

      case status = 'PAID':
        return '#fff'

      case status = 'REFUSED':
        return '#fff'

      default:
        return '#fff'
    }
  }, [])

  const handleFilterList = useCallback(async (e: any) => {
    setLoading(true)

    try {
      const { data } = await api.get('/payments', {
        params: {
          pag: 1,
          search: e,
          all: true,
          status: [1, 2, 3, 4],
          paymentKind: 0 // contas à pagar
        }
      })

      console.log('response: ', data)

      setData(data?.data)
      setTotal(data.total)
    } catch (error) {
      console.log('erro ao pegar lista: ', error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [])

  const debouncedFetch = useCallback(
    debounce(async (value) => await handleFilterList(value), 1500), // 500ms de atraso
    [handleFilterList]
  )

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const value = e.target.value
    debouncedFetch(value)
  }

  return (
    <Can permissions={['bills_to_pay.list', 'bills_to_pay.create', 'bills_to_pay.all']} page>
    <Box>

      <Header />

      <Flex flexDir="column" w="100%" my="1" maxWidth={1480} mx="auto" px={['4', '4', '6']}>
        <Sidebar showDrawer />

        <Box flex="1" borderRadius={8} bg="white" pb='20px' >
          <Flex mb="8" justify="space-between" align="center" p="8" pb="0">
            <Heading size="lg" fontWeight="normal" color="wine.primary">
            Contas à Pagar

            <Flex alignItems="center" gap="18px">
            <Flex alignItems="center" marginTop="15px">
                  <Box width="20px" height="20px" borderRadius="100%" backgroundColor="#f8de7e" marginRight="10px" /><Text fontSize="14px" fontWeight="bold">Requisitado</Text>
                </Flex>

                <Flex alignItems="center" marginTop="15px">
                  <Box width="20px" height="20px" borderRadius="100%" backgroundColor="#5dbb63" marginRight="10px" /><Text fontSize="14px" fontWeight="bold">Efetuado</Text>
                </Flex>

                <Flex alignItems="center" marginTop="15px">
                  <Box width="20px" height="20px" borderRadius="100%" backgroundColor="#fa8072" marginRight="10px" /><Text fontSize="14px" fontWeight="bold">Recusado</Text>
                </Flex>

                <Flex alignItems="center" marginTop="15px">
                  <Box width="20px" height="20px" borderRadius="100%" backgroundColor="#899499" marginRight="10px" /><Text fontSize="14px" fontWeight="bold">Reembolso já solicitado</Text>
                </Flex>

                <Flex alignItems="center" marginTop="15px">
                  <Box width="20px" height="20px" borderRadius="100%" backgroundColor="#528fde" marginRight="10px" /><Text fontSize="14px" fontWeight="bold">Em espera</Text>
                </Flex>
          </Flex>
            </Heading>
            <SearchBox placeholder="Buscar..." onChangeFunction={handleInputChange} />

          </Flex>

          {loading ? (
            <Flex justify="center">
              <Spinner />
            </Flex>
          ) : error ? (
            <Flex justify="center">
              <Text>Erro ao carregar a lista de pagamentos</Text>
            </Flex>
          ) : (
            <>
              <Table colorScheme="blue">
                <Thead>
                  <Tr>

                    <Th textAlign="center" fontSize="14px">Prestador</Th>
                    <Th textAlign="center" fontSize="14px" >Solicitante</Th>
                    <Th textAlign="center" fontSize="13px" w="120px" >Departamento</Th>
                    <Th textAlign="center" fontSize="14px">Tipo</Th>
                    <Th textAlign="center" fontSize="14px">Serviço</Th>
                    <Th textAlign="center" fontSize="14px" w="80px">Proposta</Th>
                    <Th textAlign="center" fontSize="14px" w="80px">Unidade</Th>
                    <Th textAlign="center" fontSize="14px" p={0}>Parcela</Th>
                    <Th textAlign="center" fontSize="14px">Valor</Th>
                    <Th textAlign="center" fontSize="13px" p={0}>Pagamento</Th>

                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((i) => (
                    <Tr key={i.id} backgroundColor={makeBg(i.statusLabel, i.refund_requested)}>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize="14px">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel, i.refund_requested)}>{i?.provider?.name}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize="14px">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel, i.refund_requested)}>{i?.collaborator?.name}</Text>
                          </Link>
                      </Td>

                      <Td w="120px" p={0}>
                          <Link color="wine.500" textAlign="center" fontSize="14px">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel, i.refund_requested)}>{i?.collaborator?.department}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize="14px">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel, i.refund_requested)}>{typePaymentsOptions[i.typeLabel]}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize="14px">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel, i.refund_requested)}>{i?.service?.name || 'Não encontrado'}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize="14px">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel, i.refund_requested)}>{i.proposal.number}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize="14px">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel, i.refund_requested)}>{i?.unit?.id ? i?.unit?.name || i?.unit?.name_approvation || 'Nome da unidade não informado' : 'Unidade removida'}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize="14px" w="80px">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel, i.refund_requested)}>{i.installment}</Text>
                          </Link>
                      </Td>

                      <Td>
                          <Link color="wine.500" textAlign="center" fontSize="14px">
                            <Text fontWeight="bold" color={makeColor(i.statusLabel, i.refund_requested)}>{i.value_formatted}</Text>
                          </Link>
                      </Td>

                      <Td p={0}>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center" fontSize="13px" color={makeColor(i.statusLabel, i.refund_requested)}>{i?.payment_day}</Text>
                          </Link>
                      </Td>

                      {/* <Td>
                          <Link color="wine.500">
                            <Text fontWeight="bold" textAlign="center" fontSize="14px" color={makeColor(i.statusLabel,  i.refund_requested)}>{STATUS[i.statusLabel]}</Text>
                          </Link>
                      </Td> */}

                      <Td>
                        <Flex>
                          {/* {isWideScreen ? (
                            <Button
                              as="button"
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              colorScheme="teal"
                              leftIcon={
                                <Icon as={AiOutlineUser} fontSize="16" />
                              }
                            >
                              Perfil
                            </Button>
                          ) : (
                            <IconButton
                              mr="3"
                              colorScheme="teal"
                              aria-label="Edit Content"
                              icon={<Icon as={AiOutlineUser} size="30" />}
                            />
                          )} */}

{isWideScreen
  ? (

    <IconButton
    href={`/bills-pay/create/${i.id}`}
    as="a"
                              mr="3"
                              bg="wine.200"
                              aria-label="Edit Content"
                              colorScheme="wine"
                              icon={<Icon as={RiEye2Fill} size="30" />}
                            />

//   <Button
//   href={`/bills-pay/create/${i.id}`}
//     as="a"
//     size="sm"
//     fontSize="sm"
//     width="30px"
//     bg="wine.200"
//     colorScheme="wine"
//     alignItems="center"
//     justifyItems={}
//     leftIcon={
//       <Icon as={RiEye2Fill} fontSize="16" />
//     }
//   >

// </Button>
    )
  : (
                            <IconButton
                              mr="3"
                              colorScheme="green"
                              aria-label="Edit Content"
                              icon={<Icon as={RiPencilLine} size="30" />}
                            />
    )}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Box p="8">
              <Pagination
                totalCountOfRegisters={total}
                currentPage={page}
                onPageChange={setPage}
              />
              </Box>

            </>
          )}
        </Box>
      </Flex>
    </Box>
    </Can>
  )
}

export { BillsToPayList }
